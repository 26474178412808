// import { useForm, getFormProps } from '@conform-to/react'
import { parseWithZod } from '@conform-to/zod';
import { invariantResponse } from '@epic-web/invariant';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { cssBundleHref } from '@remix-run/css-bundle';
import {
  type LoaderFunctionArgs,
  json,
  type HeadersFunction,
  type LinksFunction,
  type MetaFunction,
  type ActionFunctionArgs } from
'@remix-run/node';
import {
  Form,
  Link,
  NavLink,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  // useFetcher,
  useFetchers,
  useLoaderData,
  useMatches
  // useSubmit,
} from '@remix-run/react';
import { withSentry } from '@sentry/remix';
import { Fragment, useRef } from 'react';
import { HoneypotProvider } from 'remix-utils/honeypot/react';
import { z } from 'zod';
import { GeneralErrorBoundary } from './components/error-boundary.tsx';
// import { ErrorList } from './components/forms.tsx'
import { EpicProgress } from './components/progress-bar.tsx';
import { EpicToaster } from './components/toaster.tsx';
import { Button } from './components/ui/button.tsx';
import { Icon, href as iconsHref } from './components/ui/icon.tsx';
import tailwindStyleSheetUrl from './styles/tailwind.css';
import { getUserId, logout } from './utils/auth.server.ts';
import { ClientHintCheck, getHints, useHints } from './utils/client-hints.tsx';
import { prisma } from './utils/db.server.ts';
import { getEnv } from './utils/env.server.ts';
import { honeypot } from './utils/honeypot.server.ts';
import {
  combineHeaders,
  getDomainUrl,
  getUserImgSrc,
  cn } from
'./utils/misc.tsx';
import { useNonce } from './utils/nonce-provider.ts';
import { type Theme, setTheme, getTheme } from './utils/theme.server.ts';
import { makeTimings, time } from './utils/timing.server.ts';
import { getToast } from './utils/toast.server.ts';
import { useOptionalUser, useUser } from './utils/user.ts';

export const links: LinksFunction = () => {
  return [
  // Preload svg sprite as a resource to avoid render blocking
  { rel: 'preload', href: iconsHref, as: 'image' },
  // Preload CSS as a resource to avoid render blocking
  { rel: 'preload', href: tailwindStyleSheetUrl, as: 'style' },
  cssBundleHref ? { rel: 'preload', href: cssBundleHref, as: 'style' } : null,
  { rel: 'mask-icon', href: '/favicons/mask-icon.svg' },
  {
    rel: 'alternate icon',
    type: 'image/png',
    href: '/favicons/favicon-32x32.png'
  },
  { rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' }, (
  {
    rel: 'manifest',
    href: '/site.webmanifest',
    crossOrigin: 'use-credentials'
  } as const), // necessary to make typescript happy
  //These should match the css preloads above to avoid css as render blocking resource
  { rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' },
  { rel: 'stylesheet', href: tailwindStyleSheetUrl },
  cssBundleHref ? { rel: 'stylesheet', href: cssBundleHref } : null].
  filter(Boolean);
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
  { title: data ? 'SistOpt' : 'Error | SistOpt' },
  { name: 'description', content: `Sistema de Óptica Virtual` }];

};

export async function loader({ request }: LoaderFunctionArgs) {
  const timings = makeTimings('root loader');
  const userId = await time(() => getUserId(request), {
    timings,
    type: 'getUserId',
    desc: 'getUserId in root'
  });

  const user = userId ?
  await time(
    () =>
    prisma.user.findUniqueOrThrow({
      select: {
        id: true,
        name: true,
        username: true,
        image: { select: { id: true } },
        roles: {
          select: {
            name: true,
            permissions: {
              select: { entity: true, action: true, access: true }
            }
          }
        }
      },
      where: { id: userId }
    }),
    { timings, type: 'find user', desc: 'find user in root' }
  ) :
  null;
  if (userId && !user) {
    console.info('something weird happened');
    // something weird happened... The user is authenticated but we can't find
    // them in the database. Maybe they were deleted? Let's log them out.
    await logout({ request, redirectTo: '/' });
  }
  const { toast, headers: toastHeaders } = await getToast(request);
  const honeyProps = honeypot.getInputProps();

  return json(
    {
      user,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {
          theme: getTheme(request)
        }
      },
      ENV: getEnv(),
      toast,
      honeyProps
    },
    {
      headers: combineHeaders(
        { 'Server-Timing': timings.toString() },
        toastHeaders
      )
    }
  );
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    'Server-Timing': loaderHeaders.get('Server-Timing') ?? ''
  };
  return headers;
};

const ThemeFormSchema = z.object({
  theme: z.enum(['system', 'light', 'dark'])
});

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const submission = parseWithZod(formData, {
    schema: ThemeFormSchema
  });

  invariantResponse(submission.status === 'success', 'Invalid theme received');

  const { theme } = submission.value;

  const responseInit = {
    headers: { 'set-cookie': setTheme(theme) }
  };

  return json({ result: submission.reply() }, responseInit);
}

function Document({
  children,
  nonce,
  // theme = 'light',
  env = {}





}: {children: React.ReactNode;nonce: string;theme?: Theme;env?: Record<string, string>;}) {
  return (
    // <html lang="en" className={`${theme} h-full overflow-x-hidden`}>
    <html lang="en" className={`light h-full overflow-x-hidden`}>
			<head>
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				<Links />
			</head>
			<body className="bg-background text-foreground">
				{children}
				<script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`
          }} />

				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
				<LiveReload nonce={nonce} />
			</body>
		</html>);

}

function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();
  const user = useOptionalUser();
  const theme = useTheme();
  const matches = useMatches();
  const isOnForm = matches.find(
    (m) => m.id.includes('new') || m.id.includes('edit')
  );

  const isOnMarketingPage =
  matches.find((m) => m.id.includes('marketing')) ||
  matches.find((m) => m.id.includes('auth'));
  // const isOnSearchPage = matches.find(m => m.id === 'routes/users+/index')
  // const searchBar = isOnSearchPage ? null : <SearchBar status="idle" />
  const navigation = [
  { name: 'Inicio', href: '/dashboard', current: true },
  { name: 'Pacientes', href: '/patients', current: false },
  // { name: 'Productos', href: '/products', current: false },
  // { name: 'Compras', href: '/orders', current: false },
  { name: 'Configuración', href: '/settings', current: false }];


  return (
    <Document nonce={nonce} theme={theme} env={data.ENV}>
			<div className="flex h-screen flex-col justify-between">
				{!isOnMarketingPage ?
        <header className="pb-6 text-white">
						<Disclosure as="nav" className="bg-navbar text-navbar-foreground">
							{({ open }) =>
            <>
									<div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
										<div className="relative flex h-16 items-center justify-between">
											<div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
												{/* Mobile menu button*/}
												<Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
													<span className="absolute -inset-0.5" />
													<span className="sr-only">Open main menu</span>
													{open ?
                      <Icon
                        name="cross-1"
                        className="block h-6 w-6"
                        aria-hidden="true" /> :


                      <Icon
                        name="hamburger-menu"
                        className="block h-6 w-6"
                        aria-hidden="true" />}


												</Disclosure.Button>
											</div>
											<div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
												<div className="flex flex-shrink-0 items-center">
													<Link to="/">
														<div className="text-2xl font-bold">SistOpt</div>
													</Link>
												</div>
												<div className="hidden sm:ml-6 sm:block">
													<div className="flex space-x-4">
														{navigation.map((item) =>
                        <NavLink
                          key={item.name}
                          className={({ isActive }) =>
                          cn(
                            isActive ?
                            'text-primary underline underline-offset-2' :
                            ' text-nav-foreground hover:text-primary',
                            'select-none rounded-md px-3 py-2 text-sm font-medium'
                          )}

                          to={item.href}>

																{item.name}
															</NavLink>
                        )}
													</div>
												</div>
											</div>
											<div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
												{/* <button
                      type="button"
                      className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                      <Icon
                      name="bell"
                      className="h-6 w-6"
                      aria-hidden="true"
                      />
                      </button> */}

												{/* Profile dropdown */}
												{user ?
                    <UserDropdown /> :

                    <Button asChild variant="link" size="sm">
														<Link to="/login">Log In</Link>
													</Button>}

											</div>
										</div>
									</div>

									<Disclosure.Panel className="sm:hidden">
										<div className="space-y-1 px-2 pb-3 pt-2">
											{navigation.map((item) =>
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={cn(
                      item.current ?
                      'bg-gray-900 text-white' :
                      'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'block select-none rounded-md px-3 py-2 text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}>

													{item.name}
												</Disclosure.Button>
                  )}
										</div>
									</Disclosure.Panel>
								</>}

						</Disclosure>
					</header> :
        null}

				<div className="flex-1">
					<Outlet />
				</div>

				{!isOnMarketingPage && !isOnForm ?
        <div className="container flex justify-between pb-5">
						<Link to="/">
							<div className="font-extrabold">
								412
								<span className="font-light">Software</span>
								<span className="font-normal">
									{' '}
									© {new Date().getFullYear()}
								</span>
							</div>
						</Link>
						{/* <ThemeSwitch userPreference={data.requestInfo.userPrefs.theme} /> */}
					</div> :
        null}
			</div>
			<EpicToaster toast={data.toast} />
			<EpicProgress />
		</Document>);

}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <HoneypotProvider {...data.honeyProps}>
			<App />
		</HoneypotProvider>);

}

export default withSentry(AppWithProviders);

function UserDropdown() {
  const user = useUser();
  // const submit = useSubmit()
  const formRef = useRef<HTMLFormElement>(null);
  return (
    <Menu as="div" className="relative ml-3">
			<div>
				<Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
					<span className="absolute -inset-1.5" />
					<span className="sr-only">Open user menu</span>
					<img
            className="h-8 w-8 rounded-full object-cover"
            alt={user.name ?? user.username}
            src={getUserImgSrc(user.image?.id)} />

				</Menu.Button>
			</div>
			<Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">

				<Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					<Menu.Item>
						{({ active }) =>
            <Link
              prefetch="intent"
              to={`/users/${user.username}`}
              className={cn(
                active ? 'bg-gray-100' : '',
                'block px-4 py-2 text-sm text-gray-700'
              )}>

								Perfil
							</Link>}

					</Menu.Item>
					<Menu.Item>
						{({ active }) =>
            <Form action="/logout" method="POST" ref={formRef}>
								<button
                type="submit"
                className={cn(
                  active ? 'bg-gray-100' : '',
                  'block w-full px-4 py-2 text-start text-sm text-gray-700'
                )}>

									Cerrar sesión
								</button>
							</Form>}

					</Menu.Item>
				</Menu.Items>
			</Transition>
		</Menu>);

}

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
export function useTheme() {
  // TODO: uncomment this when we have support for dark theme
  const hints = useHints();
  // const requestInfo = useRequestInfo()
  // const optimisticMode = useOptimisticThemeMode()
  // if (optimisticMode) {
  // 	return optimisticMode === 'system' ? hints.theme : optimisticMode
  // }
  // return requestInfo.userPrefs.theme ?? hints.theme
  return hints.theme;
}

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
export function useOptimisticThemeMode() {
  const fetchers = useFetchers();
  const themeFetcher = fetchers.find((f) => f.formAction === '/');

  if (themeFetcher && themeFetcher.formData) {
    const submission = parseWithZod(themeFetcher.formData, {
      schema: ThemeFormSchema
    });

    if (submission.status === 'success') {
      return submission.value.theme;
    }
  }
}

// function ThemeSwitch({ userPreference }: { userPreference?: Theme | null }) {
// 	const fetcher = useFetcher<typeof action>()

// 	const [form] = useForm({
// 		id: 'theme-switch',
// 		lastResult: fetcher.data?.result,
// 	})

// 	const optimisticMode = useOptimisticThemeMode()
// 	const mode = optimisticMode ?? userPreference ?? 'system'
// 	const nextMode =
// 		mode === 'system' ? 'light' : mode === 'light' ? 'dark' : 'system'
// 	const modeLabel = {
// 		light: (
// 			<Icon name="sun">
// 				<span className="sr-only">Light</span>
// 			</Icon>
// 		),
// 		dark: (
// 			<Icon name="moon">
// 				<span className="sr-only">Dark</span>
// 			</Icon>
// 		),
// 		system: (
// 			<Icon name="laptop">
// 				<span className="sr-only">System</span>
// 			</Icon>
// 		),
// 	}

// 	return (
// 		<fetcher.Form method="POST" {...getFormProps(form)}>
// 			<input type="hidden" name="theme" value={nextMode} />
// 			<div className="flex gap-2">
// 				<button
// 					type="submit"
// 					className="flex h-8 w-8 cursor-pointer items-center justify-center"
// 				>
// 					{modeLabel[mode]}
// 				</button>
// 			</div>
// 		</fetcher.Form>
// 	)
// }

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>);

}